import cn from 'classnames';

type Props = {
  heading: string;
  body: string;
  showConfirmButton: boolean;
  showCancelButton: boolean;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm(): void;
  onCancel(): void;
  hidden: boolean;
  modalType: 'error' | 'info';
};

const Modal = ({
  heading,
  body,
  showConfirmButton,
  showCancelButton,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  hidden,
  modalType,
}: Props) => {
  const errorIcon = (
    <svg
      className="h-6 w-6 text-red-600"
      stroke="currentColor"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
      />
    </svg>
  );
  return (
    <div className={cn({ hidden: hidden })}>
      <div className="z-10 fixed inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>

        <div
          className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                {errorIcon}
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-xl leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {heading}
                </h3>
                <div className="mt-2">
                  <p className="leading-5 text-gray-700">{body}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span
              className={cn(
                'flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto',
                { hidden: !showConfirmButton },
              )}
            >
              <button
                type="button"
                className={cn(
                  'inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5',
                  {
                    'btn-red': modalType === 'error',
                    'btn-indigo': modalType === 'info',
                  },
                )}
                onClick={onConfirm}
              >
                {confirmLabel}
              </button>
            </span>

            <span
              className={cn(
                'mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto',
                { hidden: !showCancelButton },
              )}
            >
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={onCancel}
              >
                {cancelLabel}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  showConfirmButton: false,
  showCancelButton: false,
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  onConfirm: () => {},
  onCancel: () => {},
  hidden: false,
  modalType: 'error',
} as Partial<Props>;

export default Modal;
