import { v4 } from 'uuid';
import { User, Organization } from 'interfaces';

export function getNameFromLocalStorage() {
  return window.localStorage.getItem('player_name');
}

export function saveNameToLocalStorage(name: string) {
  if (!window.localStorage.getItem('player_id')) {
    window.localStorage.setItem('player_id', v4());
  }
  return window.localStorage.setItem('player_name', name);
}

export function saveAvatarToLocalStorage(avatar: any) {
  return window.localStorage.setItem('player_avatar', JSON.stringify(avatar));
}

export function getPlayerID() {
  return window.localStorage.getItem('player_id');
}

export function getAvatarFromLocalStorage() {
  const avatar = window.localStorage.getItem('player_avatar');
  if (avatar !== null) {
    return JSON.parse(avatar);
  } else {
    return null;
  }
}

export function getLocalUser() {
  const id = getPlayerID();
  const name = getNameFromLocalStorage();
  const avatar = getAvatarFromLocalStorage();

  if (id && name && avatar) {
    const u: User = { id, name, avatar };
    return u;
  }

  return null;
}

export function getURLForAvatar(avatar: any) {
  let params = [];

  for (let [key, value] of Object.entries(avatar)) {
    params.push(`${key}=${value}`);
  }

  return 'https://avataaars.io/?' + params.join('&');
}

export function getLocalOrganization() {
  const o = window.localStorage.getItem('organization');
  if (o) {
    return JSON.parse(o) as Organization;
  } else {
    return null;
  }
}

export function saveOrganizationToLocalStorage(o: Organization) {
  return window.localStorage.setItem('organization', JSON.stringify(o));
}

export function clearLocalOrganization() {
  return window.localStorage.removeItem('organization');
}
