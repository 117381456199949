import { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import Link from 'next/link';
import { getLocalOrganization } from 'utils/userDetails';
import { Organization } from 'interfaces';
import GameListing from 'components/GameListing';

const IndexPage = () => {
  const [org, setOrg] = useState<Organization>();

  useEffect(() => {
    let o = getLocalOrganization();
    if (o) {
      setOrg(o);
    }
  }, []);

  return (
    <Layout title="Welcome to your Lounge!">
      {org ? (
        <div>
          <div className="mt-4 p-4 bg-white rounded-md shadow-md border border-gray-300 leading-relaxed flex justify-between">
            <div>
              <h2 className="text-xl font-semibold">
                Hop into {org.name}'s Lounge!
              </h2>
              <p>
                Join the lounge to see who's online and what games are going on!
              </p>
            </div>

            <div className="py-4">
              <Link href={`/lounge/${org.slug}`}>
                <button className="btn btn-indigo">Enter the Lounge</button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <h1 className="mt-4 text-center text-2xl font-bold">Available Games</h1>

      <div className="pb-20">
        <GameListing organization={org} />
      </div>
    </Layout>
  );
};

export default IndexPage;
