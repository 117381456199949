export const unMutedMicIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M15 11.5C15 13.16 13.66 14.5 12 14.5C10.34 14.5 9 13.16 9 11.5V5.5C9 3.84 10.34 2.5 12 2.5C13.66 2.5 15 3.84 15 5.5V11.5ZM12 16.5C14.76 16.5 17 14.26 17 11.5H19C19 15.03 16.39 17.93 13 18.42V21.5H11V18.42C7.60999 17.93 5 15.03 5 11.5H7C7 14.26 9.23999 16.5 12 16.5Z"
    />
  </svg>
);

export const mutedMicIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M10.72 5.39C10.72 4.73 11.2599 4.18999 11.92 4.18999C12.58 4.18999 13.1199 4.73 13.1199 5.39L13.11 9.3L14.92 11.09V5.49C14.92 3.82999 13.58 2.49 11.92 2.49C10.3799 2.49 9.12994 3.65 8.95996 5.14L10.72 6.89999V5.39ZM18.92 11.49H17.22C17.22 12.07 17.12 12.62 16.95 13.13L18.22 14.4C18.66 13.52 18.92 12.53 18.92 11.49ZM2.91998 4.76L4.32996 3.35L21.08 20.1L19.67 21.51L15.47 17.31C14.69 17.76 13.83 18.08 12.92 18.21V21.49H10.92V18.21C7.63995 17.72 4.91998 14.9 4.91998 11.49H6.62C6.62 14.49 9.15997 16.59 11.92 16.59C12.73 16.59 13.52 16.4 14.23 16.07L12.57 14.41C12.36 14.46 12.15 14.49 11.92 14.49C10.26 14.49 8.91998 13.15 8.91998 11.49V10.76L2.91998 4.76Z"
    />
  </svg>
);

export const speakerIcon = (
  <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none">
    <path
      d="M14 5.29V3.23C18.01 4.14001 21 7.72 21 12C21 16.28 18.01 19.86 14 20.77V18.71C16.89 17.85 19 15.17 19 12C19 8.83 16.89 6.15 14 5.29ZM3 15V9H7L12 4V20L7 15H3ZM10 15.17V8.83L7.83002 11H5V13H7.83002L10 15.17ZM16.5 12C16.5 10.23 15.48 8.71 14 7.97V16.02C15.48 15.29 16.5 13.77 16.5 12Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export const inviteIcon = (
  <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none">
    <path
      d="M15 12C17.21 12 19 10.21 19 8C19 5.78998 17.21 4 15 4C12.79 4 11 5.78998 11 8C11 10.21 12.79 12 15 12ZM15 6C16.1 6 17 6.90002 17 8C17 9.09998 16.1 10 15 10C13.9 10 13 9.09998 13 8C13 6.90002 13.9 6 15 6ZM7 18C7 15.34 12.33 14 15 14C17.67 14 23 15.34 23 18V20H7V18ZM9 18C9.21997 17.28 12.3101 16 15 16C17.7 16 20.8 17.29 21 18H9ZM6 12V15H4V12H1V10H4V7H6V10H9V12H6Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export const menuIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10C4.90002 10 4 10.9 4 12C4 13.1 4.90002 14 6 14C7.09998 14 8 13.1 8 12C8 10.9 7.09998 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM10 12C10 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14C10.9 14 10 13.1 10 12Z"
      fill="currentColor"
    />
  </svg>
);

export const closeIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="currentColor"
    />
  </svg>
);

export const undoIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M12.265 8.5C9.615 8.5 7.215 9.49 5.365 11.1L1.765 7.5V16.5H10.765L7.145 12.88C8.535 11.72 10.305 11 12.265 11C15.805 11 18.815 13.31 19.865 16.5L22.235 15.72C20.845 11.53 16.915 8.5 12.265 8.5Z"
      fill="currentColor"
    />
  </svg>
);

export const playersIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12C10.9301 12 12.5 10.43 12.5 8.5C12.5 6.57001 10.9301 5 9 5C7.06995 5 5.5 6.57001 5.5 8.5C5.5 10.43 7.06995 12 9 12ZM2 17.25C2 14.92 6.66003 13.75 9 13.75C11.34 13.75 16 14.92 16 17.25V19H2V17.25ZM9 15.75C7.20996 15.75 5.17993 16.42 4.33997 17H13.6599C12.8199 16.42 10.7899 15.75 9 15.75ZM10.5 8.5C10.5 7.66998 9.82996 7 9 7C8.17004 7 7.5 7.66998 7.5 8.5C7.5 9.33002 8.17004 10 9 10C9.82996 10 10.5 9.33002 10.5 8.5ZM16.04 13.81C17.2001 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM18.5 8.5C18.5 10.43 16.9301 12 15 12C14.46 12 13.96 11.87 13.5 11.65C14.13 10.76 14.5 9.66998 14.5 8.5C14.5 7.33002 14.13 6.23999 13.5 5.34998C13.96 5.13 14.46 5 15 5C16.9301 5 18.5 6.57001 18.5 8.5Z"
      fill="currentColor"
    />
  </svg>
);

export const clockIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 2C6.46997 2 2 6.48001 2 12C2 17.52 6.46997 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48001 17.52 2 11.99 2ZM12 20C7.57996 20 4 16.42 4 12C4 7.57999 7.57996 4 12 4C16.42 4 20 7.57999 20 12C20 16.42 16.42 20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z"
      fill="currentColor"
    />
  </svg>
);

export const checkMark = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M8.79496 15.875L4.62496 11.705L3.20496 13.115L8.79496 18.705L20.795 6.70501L19.385 5.29501L8.79496 15.875Z"
      fill="currentColor"
    />
  </svg>
);

export const chat = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2H3C2.45001 2 2 2.45001 2 3V17L6 13H16C16.55 13 17 12.55 17 12V3C17 2.45001 16.55 2 16 2ZM15 4V11H5.16998L4 12.17V4H15ZM19 6H21C21.55 6 22 6.45001 22 7V22L18 18H7C6.45001 18 6 17.55 6 17V15H19V6Z"
      fill="currentColor"
    />
  </svg>
);

export const send = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 10L1.50999 3L22.5 12L1.50999 21L1.5 14L16.5 12L1.5 10ZM3.50999 6.03L11.02 9.25L3.5 8.25L3.50999 6.03ZM11.01 14.75L3.5 17.97V15.75L11.01 14.75Z"
      fill="currentColor"
    />
  </svg>
);
